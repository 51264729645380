import React, { useEffect, useRef, useState } from 'react';
import styles from './ProductsSearchedView.module.scss';
import * as getProductsSearchedService from './services/get-products-search.service';
import { ProductPaginated } from './models/product-paginated.model';
import Paginator from '../../../../shared/paginator/Paginator';
import ProductCard from '../../../../shared/product-card/ProductCard';
import { Product } from '../../models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import Search from '../search/Search';

export default function ProductsSearchedView(props: any) {
    const filters = useSelector((state: RootState) => state.filters);

    const [productsObject, setProductsObject] = useState<ProductPaginated>();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const main_container_ref = useRef<any>(null);

    useEffect(() => {
        getProductsSearchedService.getProductsSearchModel(currentPage + 1, perPage, filters).then((response: any) => {
            let result = response.data as ProductPaginated;
            setProductsObject(result);
        })
    }, [filters, currentPage, perPage]);

    useEffect(() => {
        getProductsSearchedService.getProductsSearchModel(currentPage + 1, perPage, filters).then((response: any) => {
            setProductsObject(response.data);
            setCurrentPage(0);
        })
    }, [filters]);

    const handlePageClick = (selectedPage: any) => {
        setCurrentPage(selectedPage.selected);
        main_container_ref?.current.scrollIntoView();
    };

    const handlePerPageChange = (event: any) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleCartItemsChange = () => {
        props.onCartItemsChange();
    }

    return (
        <div className={styles.main_container} ref={main_container_ref}>
            <Search />
            <div className={styles.cards_container}>
                {productsObject?.items && productsObject?.items?.map((product: Product, index: number) => (
                    <ProductCard key={product.id} product={product} onCartItemsChange={handleCartItemsChange}>
                    </ProductCard>
                ))}
            </div>
            <div className={styles.paginator_container}>
                {
                    productsObject &&
                    <Paginator
                        perPage={perPage}
                        onPageClick={handlePageClick}
                        onPerPageChange={handlePerPageChange}
                        currentPage={currentPage ? currentPage : 0}
                        totalPages={productsObject ? productsObject?.totalPages as number : 0} />
                }
            </div>
        </div>
    )
}
