import { useEffect, useState } from 'react';
import styles from "./Header.module.scss";
import { Link, useNavigate } from 'react-router-dom';
import * as authService from '../../auth/services/auth.service';
import { useTranslation } from 'react-i18next';
import { getCartItems } from '../../components/cart/services/cart.service';
import { CartItem } from '../../components/cart/models';
import { Language } from '../models';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getCmsUrlAndTitleByLanguagAndCode } from '../../containers/cms-container/services';
import { ABOUT, CONTACT, HOW_TO_BUY, JOIN_US, HOW_TO_SELL } from '../constants/cms-codes.constants';
import { CmsTitlesAndUrls } from '../../containers/cms-container/models/cms-titles-url-by-code';
import { getLanguages } from '../services/language.service';

const phoneIcon = require("../../assets/icons/phone.svg").default;
const envelopeIcon = require("../../assets/icons/envelope.svg").default;
const logoIcon = require("../../assets/icons/koszyk_chojnicki_logo.svg").default;
const cartIcon = require("../../assets/icons/cart.svg").default;
const handShakeIcon = require("../../assets/icons/KLUB.svg").default;
const userIcon = require("../../assets/icons/login.svg").default;

export default function Header(props: any) {
    const currency = useSelector((state: RootState) => state.currency);
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const [cartItemsNumber, setCartItemsNumber] = useState<number>(0);
    const [cartWorth, setCartWorth] = useState<number>(0);
    const [titlesAndUrls, setTitlesAndUrls] = useState<CmsTitlesAndUrls>({});
    const [loading, setLoading] = useState(true);
    const [allLanguages, setAllLanguages] = useState<Language[]>([]);


    const getTitleAndUrlForHeader = async (): Promise<void[]> => {
        const codes = [HOW_TO_BUY, CONTACT, JOIN_US, ABOUT, HOW_TO_SELL];
        const savedLanguageCode = localStorage.getItem('lang');
        if (savedLanguageCode) {
            const promises = codes.map(async (code) => {
                const response = await getCmsUrlAndTitleByLanguagAndCode(code, savedLanguageCode);
                setTitlesAndUrls((prevTitlesAndUrls) => ({
                    ...prevTitlesAndUrls,
                    [code]: response.data,
                }));
            });
            await Promise.all(promises);
            setLoading(false);
        }

        return Promise.resolve([]);
    };

    const handleSetLanguages = () => {
        getLanguages().then((result) => {
            if (result) setAllLanguages(result?.data as Language[]);
        });

    };

    useEffect(() => {
        getCartItems().then((result) => {
            if (Array.isArray(result)) {
                setCartItemsNumber(result.length as number);
                calculateCartWorth(result as CartItem[]);
            }
        })
    }, [props.cartItemsChanged]);

    useEffect(() => {
        handleSetLanguages();
    }, []);

    useEffect(() => {
        getTitleAndUrlForHeader();
    }, [localStorage.getItem('lang')]);

    const calculateCartWorth = (items: CartItem[]): void => {
        let sum = 0;
        items?.forEach(it => {
            sum += it.totalPrice
        });
        setCartWorth(sum);
    }

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }

    function logOut() {
        authService.logOut(navigate);
    }

    return (
        <div className={styles.main_container}>
            <div className={styles.upper_part}>
                <div className={styles.upper_part_content}>
                    <div className={styles.left_side}>
                        <div className={styles.phone}>
                            <a href="tel:+48756139999">
                                <img src={phoneIcon} alt="Phone" />
                                <span>601 445 084</span>
                            </a>
                        </div>
                        <div className={styles.email}>
                            <a href="mailto:kontakt@inkg.pl">
                                <img src={envelopeIcon} alt="Envelope" />
                                <span>kgorlich@a-co.pl</span>
                            </a>
                        </div>
                    </div >
                    <div className={styles.right_side}>
                        {
                            !authService.checkLogInStatus() ?
                                <>
                                    <span onClick={() => navigate('/register')}>{t("header.register")}</span>
                                    <span onClick={() => navigate('/login')}>{t("header.login")}</span>
                                </>
                                :
                                <>
                                    <span className={styles.blured}>{t("header.register")}</span>
                                    <span onClick={() => logOut()}>{t("header.logout")}</span>
                                </>
                        }
                    </div>
                </div >

            </div >
            <div className={styles.bottom_part}>
                <div className={styles.logo_wrapper}>
                    <img onClick={() => { navigate("/"); navigate(0) }} src={logoIcon} alt="Logo" />
                </div>

                <div className={`${styles.links_container} ${hamburgerOpen ? `${styles.open}` : ''}`}>
                    <Link to="/">{t("header.shop")}</Link>
                    <Link to="/producers">{t("header.producers")}</Link>
                    {!loading && titlesAndUrls.HOW_TO_BUY?.url && <Link to={titlesAndUrls.HOW_TO_BUY.url}>{titlesAndUrls?.HOW_TO_BUY?.title}</Link>}
                    {!loading && titlesAndUrls.HOW_TO_SELL?.url && <Link to={titlesAndUrls.HOW_TO_SELL.url}>{titlesAndUrls?.HOW_TO_SELL?.title}</Link>}
                    {!loading && titlesAndUrls.JOIN_US?.url && <Link to={titlesAndUrls.JOIN_US.url}>{titlesAndUrls.JOIN_US.title}</Link>}
                    {!loading && titlesAndUrls.CONTACT?.url && <Link to={titlesAndUrls.CONTACT.url}>{titlesAndUrls.CONTACT.title}</Link>}
                    {!loading && titlesAndUrls.PROJECT?.url && <Link to={titlesAndUrls.PROJECT.url}>{titlesAndUrls?.PROJECT?.title}</Link>}
                    {!loading && titlesAndUrls.ABOUT?.url && <Link to={titlesAndUrls.ABOUT.url} className={styles.about}>{titlesAndUrls?.ABOUT?.title}</Link>}
                </div>


                <div className={styles.icons_container}>
                    {
                        authService.checkLogInStatus() &&
                        <a className={styles.user_details} href={'/client-panel'}>
                            <img src={userIcon} alt="user-icon" />
                        </a>
                    }
                    <div className={styles.cart}>
                        <a href='/cart'>
                            <>
                                <div className={styles.quantity}>
                                    <p>{cartItemsNumber}</p>
                                </div>
                                <img src={cartIcon} alt="Cart" />
                            </>
                        </a>
                    </div>
                    {
                        cartWorth > 0 &&
                        <p className={styles.cart_worth}>{currency + " " + cartWorth.toFixed(2)}</p>
                    }
                    <div className={`${styles.hamburger_container} ${hamburgerOpen ? `${styles.hamburger_active}` : ''}`} onClick={toggleHamburger}>
                        <div className={`${styles.hamburger} ${hamburgerOpen ? `${styles.menu_open}` : ''}`}>
                            <div className={`${styles.bar} ${styles.bar1}`}></div>
                            <div className={`${styles.bar} ${styles.bar2}`}></div>
                            <div className={`${styles.bar} ${styles.bar3}`}></div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.searchbar_and_cart}> */}
                {
                    /*<div className={styles.searchbar_and_cart}>
                      <div className={styles.search_bar}>
                        <input onChange={e => { setSearchBarValue(e.target.value) }} type="search" placeholder={t("header.search") as string} />
                        <img src={searchIcon} alt="Search" onClick={() => { navigate('/products-view'); onSearch(searchBarValue) }} />
                      </div>
                    </div> */
                }
                {/* </div> */}

            </div>
        </div >
    )
}