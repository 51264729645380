import { useEffect, useState } from "react";
import styles from "./CartPanel.module.scss";
import { getCartItems } from "../../components/cart/services/cart.service";
import { CartItem } from "../../components/cart/models";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getPickupPointById } from "../../components/order/services";
import { PickupPoint } from "../map/models";
import { usePluginStore } from "react-pluggable";
import { getAvailablePickupPointsForWebshopOrder } from "../../components/client-panel/components/my-account-info/services";


export default function CartPanel(props: any) {
    const [cartItems, setCartItems] = useState<CartItem[]>();
    const [pickupPoint, setPickupPoint] = useState<PickupPoint>();
    const [showPickupPointsSelect, setShowPickupPointSelect] = useState<boolean>();
    const [availablePickupPoints, setAvailablePickupPoints] = useState<PickupPoint[]>([]);
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const currency = useSelector((state: RootState) => state.currency);
    const deleteIcon = require('../../assets/icons/deleted-icon.svg').default;


    const getAvailablePickupPointsForWebshopOrderOnInit = (items: CartItem[]): void => {
        getAvailablePickupPointsForWebshopOrder(items).then((result) => {
            if (result) {
                setAvailablePickupPoints(result.data as PickupPoint[]);
            }
        });
    };


    useEffect(() => {
        getCartItems().then((result) => {
            setCartItems(result as CartItem[])
        });
        getPickupPointOnInit();
    }, [])

    useEffect(() => {
        if (cartItems) {
            getAvailablePickupPointsForWebshopOrderOnInit(cartItems);
            updateQuantityOfCartItemsOnInit();
        }
    }, [cartItems])


    const updateQuantityOfCartItemsOnInit = () => {
        if (cartItems) {
            cartItems.forEach((f) => {
                if (f.availableQuantity && f.quantity > f.availableQuantity) {
                    f.quantity = f.availableQuantity;
                }
            })
            localStorage.setItem('cart', JSON.stringify(cartItems));
        }
    }

    const getRecentlyAddedProductId = (): string | null => {
        return localStorage.getItem("recentlyAddedProductId");
    };

    const recentlyAddedProductId = getRecentlyAddedProductId();

    const calculateTotalPrice = () => {
        const totalPrice = cartItems?.reduce((total, cartItem) => total + cartItem.totalPrice, 0) ?? 0;
        return totalPrice.toFixed(2);
    }

    const getPickupPointOnInit = () => {
        const selectedPickupPoint = localStorage.getItem('pickupPointsFilter');
        if (selectedPickupPoint) {
            getPickupPointById(selectedPickupPoint).then(response => setPickupPoint(response.data))
        }
    }

    const updateTotalPrice = (cartItem: CartItem) => {
        cartItem.totalPrice = cartItem.quantity * cartItem.pricePerUnit * (cartItem.vat + 1.0)
    }

    const handleRemoveItem = async (item: CartItem) => {
        if (!cartItems) return;
        const updatedCartItems = cartItems.filter(cartItem => cartItem.id !== item.id);
        setCartItems(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
        if (updatedCartItems.length === 0) {
            props.toggleOpenCartPanel(false)
        }
    }

    const togglePickupPointsSelect = (value: boolean) => {
        setShowPickupPointSelect(value);
        if (value === false) {
            getPickupPointOnInit();
        }
    }

    const handleDoubleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: CartItem) => {
        let inputValue = e.target.value.replace(",", ".");

        if (cartItems) {
            const updatedCartItems = [...cartItems];
            const index = updatedCartItems.findIndex(cartItem => cartItem.id === item.id);

            if (index !== -1 && !isNaN(parseFloat(inputValue)) && item.availableQuantity) {
                let parsedValue = parseFloat(inputValue);

                if (parsedValue > item.availableQuantity) {
                    parsedValue = item.availableQuantity
                } else if (parsedValue < 0) {
                    parsedValue = 0;
                }
                if (item.minOrderQuantity && parsedValue < item.minOrderQuantity) {
                    parsedValue = item.minOrderQuantity;
                }



                parsedValue = parseFloat(parsedValue.toFixed(2));

                updatedCartItems[index].quantity = parsedValue;
                updateTotalPrice(updatedCartItems[index]);
                setCartItems(updatedCartItems);
                localStorage.setItem('cart', JSON.stringify(updatedCartItems));
            }
        }
    };

    const handleIntInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: CartItem) => {
        let inputValue = Number(e.target.value);

        if (cartItems) {
            const updatedCartItems = [...cartItems];
            const index = updatedCartItems.findIndex(cartItem => cartItem.id === item.id);

            if (index !== -1 && !isNaN(inputValue) && item.availableQuantity !== undefined) {

                if (inputValue > item.availableQuantity) {
                    updatedCartItems[index].quantity = item.availableQuantity
                } else if (inputValue < 0) {
                    updatedCartItems[index].quantity = 0;
                }
                else {
                    updatedCartItems[index].quantity = inputValue;
                }

                updateTotalPrice(updatedCartItems[index]);
                setCartItems(updatedCartItems);
                localStorage.setItem('cart', JSON.stringify(updatedCartItems));
            }
        }
    }


    const handlePlusOrMinus = (type: string, item: CartItem) => {
        let value = item.isQuantityNaturalNumber ? 1 : 0.5;

        if (cartItems) {
            const updatedCartItems = [...cartItems];
            const index = updatedCartItems.findIndex(cartItem => cartItem.id === item.id);

            if (index !== -1 && updatedCartItems[index] !== undefined) {
                const cartItem = updatedCartItems[index];
                let temporaryValue = cartItem.quantity + value;

                if (type === 'plus') {
                    if (cartItem.availableQuantity !== undefined && cartItem.quantity !== undefined) {
                        if (temporaryValue > cartItem.availableQuantity) {
                            cartItem.quantity = cartItem.availableQuantity;
                        } else {
                            cartItem.quantity = temporaryValue;
                        }
                    }
                } else if (type === 'minus') {

                    if (cartItem.minOrderQuantity) {
                        const finalValue = item.quantity - value;
                        if (finalValue < cartItem.minOrderQuantity) {
                            const valueToMinus = cartItem.minOrderQuantity - finalValue;
                            value -= valueToMinus;
                        }
                    }

                    if (cartItem.quantity > value) {
                        cartItem.quantity -= value;
                    } else {
                        cartItem.quantity = 0;
                    }

                    if (cartItem.quantity === 0) {
                        updatedCartItems.splice(index, 1);
                    }

                    if (updatedCartItems.length === 0) {
                        props.toggleOpenCartPanel(false);
                    }


                }

                updateTotalPrice(cartItem);
                setCartItems(updatedCartItems);
                localStorage.setItem('cart', JSON.stringify(updatedCartItems));
            }
        }
    };


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.content_container}>
                    <div className={styles.row + ' ' + styles.mb30}>
                        <h4>PODGLĄD KOSZYKA ({cartItems?.length})</h4>
                        <div onClick={() => props.toggleOpenCartPanel(false)}>X</div>
                    </div>
                    {pickupPoint && availablePickupPoints.length > 0 &&
                        <div className={styles.section + ' ' + styles.pickupMethods}>
                            <div className={styles.section_header}>
                                Punkt odbioru
                            </div>
                            <div className={styles.section_content}>
                                {!showPickupPointsSelect &&
                                    <div>
                                        <div className={styles.pp_name}>{pickupPoint?.name}</div>
                                        <div className={styles.pp_address}>{pickupPoint?.address.streetName + " " + pickupPoint?.address.buildingNumber}</div>
                                        <div className={styles.row}>
                                            <div className={styles.pp_address}>{pickupPoint?.address.postalCode + " " + pickupPoint?.address.city}</div>
                                            <div className={styles.button_container} onClick={() => { togglePickupPointsSelect(true) }}>
                                                <FontAwesomeIcon icon={faSync} />
                                                <button className={styles.change_button}>Zmień</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {showPickupPointsSelect &&
                                    <div>
                                        <Renderer placement={"page:selectSharedPickupPoint"} />
                                        <button className={styles.save_button} onClick={() => { togglePickupPointsSelect(false) }}>Zapisz</button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className={styles.section}>
                        <div className={styles.section_header}>
                            Podsumowanie
                        </div>

                        <div className={styles.section_content}>
                            {cartItems && cartItems?.map((cartItem) => (
                                <div key={cartItem.id} >
                                    {cartItem.productId === recentlyAddedProductId &&
                                        <div className={styles.add_prod_message}>
                                            <div className={styles.add_prod_message_icon}>
                                                <FontAwesomeIcon icon={faCircleExclamation} />
                                            </div>
                                            <p className={styles.add_prod_message_text} style={{ color: "#f2b23e", }}>Właśnie dodano ten produkt!</p>
                                        </div>
                                    }
                                    <div className={`${styles.item_row} ${styles.first_row}`}>
                                        <p>{cartItem.name}</p>
                                        <p className={styles.price_item}>{cartItem.totalPrice.toFixed(2) + " " + currency}</p>
                                    </div>
                                    <div className={styles.item_row}>
                                        <div className={styles.quantity_wrapper}>
                                            <div className={styles.plus_minus_wrapper}>
                                                <button
                                                    className={styles.minus_button}
                                                    disabled={cartItem.minOrderQuantity !== undefined &&
                                                        cartItem.quantity <= cartItem.minOrderQuantity}
                                                    onClick={() => handlePlusOrMinus('minus', cartItem)}
                                                >
                                                    <p>-</p>
                                                </button>
                                            </div>
                                            <input
                                                type={cartItem.isQuantityNaturalNumber ? 'text' : 'number'}
                                                className={styles.amount}
                                                value={String(cartItem.quantity)}
                                                onChange={cartItem.isQuantityNaturalNumber ? (event) => handleIntInputChange(event, cartItem)
                                                    : (event) => handleDoubleInputChange(event, cartItem)
                                                }
                                                min={0}
                                                step="any"
                                            />
                                            <div>
                                                {cartItem.saleUnitName}
                                            </div>
                                            <div className={styles.plus_minus_wrapper}>
                                                <button
                                                    className={styles.plus_button}
                                                    onClick={() => handlePlusOrMinus('plus', cartItem)}
                                                >
                                                    <p>+</p>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={styles.delete_row} onClick={() => handleRemoveItem(cartItem)}>
                                            <img src={deleteIcon} alt="Delete icon" />
                                            <div className={styles.delete_text}>Usuń</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.underlined_row + ' ' + styles.total_wrapper}>
                        <p className={styles.total_text}>RAZEM</p>
                        <p className={styles.total_price}>{calculateTotalPrice() + " " + currency}</p>
                    </div>
                    <div className={styles.buttons_container}>
                        <button className={styles.button} onClick={() => props.toggleOpenCartPanel(false)}>
                            KONTYNUUJ ZAKUPY
                        </button>
                        <Link className={styles.buttonLink} to="/cart" onClick={() => props.toggleOpenCartPanel(false)}>
                            PRZEJDŹ DO KOSZYKA
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
};
