import { useEffect, useState } from 'react';
import styles from './Cart.module.scss';
import { getCartItems, removeCartItem, updateCartItemQuantity } from './services/cart.service';
import { useTranslation } from 'react-i18next';
import { CartItem } from './models';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { calculateBrutto } from '../../shared/services';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getPickupPointWithSellingSessions, getSellingSessionsPaginatedService } from '../selling-sessions/service';
import { SellingSession } from '../selling-sessions/models';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { checkLogInStatus } from '../../auth/services/auth.service';
import { getDeliveries } from '../order/services';
import { DeliveryMethod } from '../order/models';
import { PickupPointWithSellingSessionsDto } from '../order/models/PickupPointWithSellingSessionsDto.model';
import { PickupPoint } from '../../shared/map/models';
import PickupPointSelectionPanel from '../order/components/pickupPointSelectionPanel/PickupPointSelectionPanel';
import CartItemImage from './components/CartItemImage';
import SellingSessionsTable from './components/selling-sessions-table/SellingSessionsTable';

export default function Cart(props: any) {

    const currency = useSelector((state: RootState) => state.currency);
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState<CartItem[]>();

    const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([]);
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<DeliveryMethod>();

    const [pickupPointMethodChecked, setPickupPointMethodChecked] = useState<boolean>(false);
    const [availablePickupPoints, setAvailablePickupPoints] = useState<PickupPoint[]>([]);
    const [selectedPickupPoint, setSelectedPickupPoint] = useState<PickupPoint | null>();
    const [pickupPointsWithSellingSessions, setPickupPointsWithSellingSessions] = useState<PickupPointWithSellingSessionsDto[]>([]);

    const [sellingSessions, setSellingSessions] = useState<SellingSession[]>();
    const [activeSellingSessions, setActiveSellingSessions] = useState<SellingSession[]>();
    const [futureSellingSessions, setFutureSellingSessions] = useState<SellingSession[]>();

    const [shouldBeButtonDisabled, setShouldBeButtonDisabled] = useState(true);

    useEffect(() => {
        fetchSellingSessions();
    }, []);

    useEffect(() => {
        getDeliveryMethodsOnOnit();
        getCartItems().then((result) => {
            const items = result as CartItem[];
            setCartItems(items);

            window.scrollTo({ top: 0 });
        });
    }, []);

    useEffect(() => {
        updateQuantityOfCartItemsOnInit();
    }, [cartItems])

    useEffect(() => {
        if (deliveryMethods.length > 0) {
            fillDeliveryMethod();
        }
    }, [deliveryMethods])

    useEffect(() => {
        fillPickupPoint();
    }, [pickupPointsWithSellingSessions])

    useEffect(() => {
        if (sellingSessions !== null && sellingSessions !== undefined) {
            const now = new Date().getTime();

            const activeSessions = sellingSessions.filter(session => {
                const startOrderingDate = new Date(session.startOrderingDate).getTime();
                const endOrderingDate = new Date(session.endOrderingDate).getTime();
                return now >= startOrderingDate && now <= endOrderingDate;
            });

            const futureSessions = sellingSessions
                .filter(session => {
                    const startOrderingDate = new Date(session.startOrderingDate).getTime();
                    return now < startOrderingDate;
                })
                .sort((a, b) => new Date(a.startOrderingDate).getTime() - new Date(b.startOrderingDate).getTime());

            if (activeSellingSessions && activeSellingSessions.length <= 0) {
                setShouldBeButtonDisabled(true);
            }

            setActiveSellingSessions(activeSessions);
            setFutureSellingSessions(futureSessions);
        }
    }, [sellingSessions]);



    useEffect(() => {
        if (cartItems !== undefined && cartItems.length > 0) {
            getPickupPointWithSellingSessions(cartItems).then((response: any) => {
                if (response) {
                    const data = response.data as PickupPointWithSellingSessionsDto[];
                    setPickupPointsWithSellingSessions(data);
                    const pickupPoints: PickupPoint[] = data.map(item => item.pickupPointToReturn);
                    setAvailablePickupPoints(pickupPoints);
                }
            }).catch(error => {
                console.error('Error fetching pickup points:', error);
            });
        }
    }, [cartItems]);

    const fetchSellingSessions = () => {
        const pickupPointId = localStorage.getItem('pickupPointsFilter')?.trim();
        const deliveryMethodId = localStorage.getItem('orderDeliveryMethodId')?.trim();
        if (deliveryMethodId !== null && deliveryMethodId !== undefined) {
            getSellingSessionsPaginatedService(1, 20, pickupPointId, deliveryMethodId).then((response: any) => {
                setSellingSessions(response.data?.items);
            });
        }
    }

    const getDeliveryMethodsOnOnit = (): void => {
        getDeliveries().then((response) => {
            if (response) {
                setDeliveryMethods(response.data as DeliveryMethod[])
            }
        })
    };

    const updateQuantityOfCartItemsOnInit = () => {
        if (cartItems) {
            cartItems.forEach((f) => {
                if (f.availableQuantity && f.quantity > f.availableQuantity) {
                    f.quantity = f.availableQuantity;
                }
            })
            localStorage.setItem('cart', JSON.stringify(cartItems));
        }
    }

    const removeItemFromCart = (itemId: string): void => {
        cartItems?.length === 1 && navigate("/");
        removeCartItem(itemId);
        props.onCartItemRemoved();

        getCartItems().then((result) => {
            setCartItems(result as CartItem[])
        });
    };

    const handleMinus = (item: CartItem, quantity: number): void => {
        if (item.minOrderQuantity) {
            const finalValue = item.quantity - quantity;
            if (finalValue < item.minOrderQuantity) {
                const valueToMinus = item.minOrderQuantity - finalValue;
                quantity -= valueToMinus;
            }
        }
        if (quantity > 0) {
            updateCartItemQuantity(item.id, false, quantity).then((cart) => {
                setCartItems(cart as CartItem[]);
                props.onCartItemsChange();
            });
        }
    };


    const handlePlus = (id: string, quantity: number): void => {
        updateCartItemQuantity(id, true, quantity).then((cart) => {
            setCartItems(cart as CartItem[]);
            props.onCartItemsChange();
        });
    }

    const calculateTotalPrice = (items: CartItem[] | undefined, withDelivery: boolean): string => {
        let sum = 0;
        items?.forEach(it => {
            sum += parseFloat(it.totalPrice.toFixed(2));
        });
        if (withDelivery && selectedDeliveryMethod) {
            sum += selectedDeliveryMethod?.price;
        }
        return sum.toFixed(2);
    }


    const checkIfZeroQuantity = (items: CartItem[] | undefined): boolean => {
        if (items) {
            for (const element of items) {
                if (element.quantity === 0) {
                    return true;
                }
            }
        } else {
            return false;
        }
        return false;
    }

    const buyButtonAction = (items: CartItem[] | undefined) => {
        if (checkIfZeroQuantity(items)) {
            toast.error(t('cart.quantityError'));
        }
        else {
            if (checkLogInStatus()) {
                navigate("/order")
            }
            else {
                navigate("/continue-or-create-account")
            }
        }
    }

    const handleDoubleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: CartItem) => {
        let inputValue = e.target.value.replace(",", ".");

        if (cartItems) {
            const updatedCartItems = [...cartItems];
            const index = updatedCartItems.findIndex(cartItem => cartItem.id === item.id);

            if (index !== -1 && !isNaN(parseFloat(inputValue)) && item.availableQuantity) {
                let parsedValue = parseFloat(inputValue);

                if (parsedValue >= item.availableQuantity) {
                    parsedValue = item.availableQuantity;
                } else if (parsedValue <= 0) {
                    parsedValue = 0;
                }
                else if (item.minOrderQuantity && parsedValue < item.minOrderQuantity) {
                    parsedValue = item.minOrderQuantity;
                }

                parsedValue = parseFloat(parsedValue.toFixed(2));
                const result = parseFloat((parsedValue * (updatedCartItems[index].pricePerUnit * (updatedCartItems[index].vat + 1))).toFixed(2));
                updatedCartItems[index].quantity = parsedValue;
                updatedCartItems[index].totalPrice = result;

                setCartItems(updatedCartItems);
                localStorage.setItem('cart', JSON.stringify(updatedCartItems));
                props.onCartItemsChange();
            }
        }
    };


    const handleIntInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: CartItem) => {
        let inputValue = Number(e.target.value);
        if (cartItems) {
            const updatedCartItems = [...cartItems];
            const index = updatedCartItems.findIndex(cartItem => cartItem.id === item.id);
            if (index !== -1 && !isNaN(inputValue) && item.availableQuantity !== undefined) {
                if (inputValue >= item.availableQuantity) {
                    inputValue = item.availableQuantity;
                    updatedCartItems[index].quantity = item.availableQuantity;
                } else if (inputValue <= 0) {
                    updatedCartItems[index].quantity = 0;
                }
                else {
                    updatedCartItems[index].quantity = inputValue;
                }
                const parsedValue = parseFloat(inputValue.toFixed(2));
                const priceWithVat = parseFloat((updatedCartItems[index].pricePerUnit * (1 + updatedCartItems[index].vat)).toFixed(2));
                updatedCartItems[index].totalPrice = parseFloat((parsedValue * priceWithVat).toFixed(2));

                setCartItems(updatedCartItems);
                localStorage.setItem('cart', JSON.stringify(updatedCartItems));
                props.onCartItemsChange();
            }
        }
    }


    const fillDeliveryMethod = () => {
        const deliveryMethodId = localStorage.getItem('orderDeliveryMethodId')?.trim();
        if (deliveryMethodId) {
            const selectedDeliveryMethodLocalStorage = deliveryMethods.find(method => method.id === deliveryMethodId);
            if (selectedDeliveryMethodLocalStorage) {
                setSelectedDeliveryMethod(selectedDeliveryMethodLocalStorage);
                if (selectedDeliveryMethodLocalStorage.code === "PICKUP_POINT") {
                    setPickupPointMethodChecked(true);
                }
            }
        }
    }

    const fillPickupPoint = () => {
        const pickupPointId = localStorage.getItem('pickupPointsFilter')?.trim();
        if (pickupPointId) {
            const selectedPickupPointLocalStorage = pickupPointsWithSellingSessions.find(point => point.pickupPointToReturn.id === pickupPointId)?.pickupPointToReturn;
            if (selectedPickupPointLocalStorage) {
                setSelectedPickupPoint(selectedPickupPointLocalStorage);
                setPickupPointMethodChecked(true);
                const pickupPointMethod = deliveryMethods.find(method => method.code === "PICKUP_POINT");
                if (pickupPointMethod) {
                    setSelectedDeliveryMethod(pickupPointMethod);
                }
            }
        }
        else {
            setSelectedPickupPoint(null);
        }
    }


    const handleDeliveryMethodChange = (deliveryMethod: DeliveryMethod): void => {
        localStorage.setItem('orderDeliveryMethodId', deliveryMethod.id);
        setSelectedDeliveryMethod(deliveryMethod);
        if (deliveryMethod?.code === "PICKUP_POINT") {
            setPickupPointMethodChecked(true);
            fetchSellingSessions();
            return;
        }
        setPickupPointMethodChecked(false);
        setSelectedPickupPoint(null);
        localStorage.removeItem('pickupPointsFilter');
        fetchSellingSessions();
    };

    const selectPickupPoint = (value: PickupPoint): void => {
        if (value && value.id) {
            setSelectedPickupPoint(value);
            localStorage.setItem('pickupPointsFilter', value.id);
            fetchSellingSessions();
        }
    };

    useEffect(() => {
        checkIfShouldBeButtonDisabled();
    }, [selectedDeliveryMethod, selectedPickupPoint, activeSellingSessions])

    const checkIfShouldBeButtonDisabled = (): void => {
        if (!selectedDeliveryMethod) {
            setShouldBeButtonDisabled(true);
        }
        else if (selectedDeliveryMethod?.code === 'PICKUP_POINT' && !selectedPickupPoint) {
            setShouldBeButtonDisabled(true);
        }
        else if (activeSellingSessions && activeSellingSessions.length <= 0) {
            setShouldBeButtonDisabled(true);
        }

        else {
            setShouldBeButtonDisabled(false);
        }
    }


    return (
        <div className={styles.cart_container}>
            <div className={styles.up_decor}></div>
            {cartItems?.length == 0 ?
                <div className={styles.main_container}>
                    <div className={styles.empty_cart}>
                        <h2 className={styles.cart_title}>Koszyk jest pusty!</h2>
                        <div className={styles.buy_button} onClick={() => navigate("/")}>
                            {t("cart.returnToShopping")}
                        </div>
                    </div>
                </div>
                :
                <div className={styles.main_container}>

                    <div className={styles.table_container}>
                        <h2 className={styles.cart_title}>{t("cart.contentsBasket")}</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th className={styles.product}>{t("cart.product")}</th>
                                    <th className={styles.price}>{t("cart.price")}</th>
                                    <th className={styles.quantity}>{t("cart.quantity")}</th>
                                    <th className={styles.saleUnitName}></th>
                                    <th className={styles.totalPrice}>{t("cart.totalPrice")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cartItems && cartItems?.map((item: CartItem, index: number) => (
                                        <tr key={index}>
                                            <td className={styles.product_column}>
                                                <CloseIcon className={styles.cross_icon} fontSize='small' onClick={() => removeItemFromCart(item?.id)} />
                                                <div className={styles.image_container}>
                                                    <CartItemImage productId={item?.productId}></CartItemImage>
                                                </div>
                                                <a href={`product-details/${item?.productId}/${item?.parentProductId}`}>{item.name}</a>
                                            </td>
                                            <td className={styles.price_column}>
                                                {currency + " " + calculateBrutto(item?.pricePerUnit, item.vat)}
                                            </td>
                                            <td className={styles.quantity_column}>
                                                <div className={styles.wrapper}>
                                                    <button
                                                        className={((item?.availableQuantity as number > 0) &&
                                                            (item.quantity !== 0)) ? styles.minus_button : `${styles.minus_button} ${styles.inactive}`}
                                                        onClick={() => handleMinus(item, item?.isQuantityNaturalNumber ? 1 : 0.5)}
                                                        disabled={
                                                            item.minOrderQuantity !== undefined &&
                                                            item.quantity <= item.minOrderQuantity
                                                        }
                                                    >
                                                        -
                                                    </button>
                                                    <input
                                                        type={item.isQuantityNaturalNumber ? 'text' : 'number'}
                                                        className={styles.amount}
                                                        value={String(item.quantity)}
                                                        onChange={item.isQuantityNaturalNumber ? (event) => handleIntInputChange(event, item)
                                                            : (event) => handleDoubleInputChange(event, item)
                                                        }
                                                        min={0}
                                                        step="any"
                                                    />
                                                    <div
                                                        className={((item?.availableQuantity as number > 0) && (item.quantity < (item?.availableQuantity as number))) ? styles.plus_button : `${styles.plus_button} ${styles.inactive}`}
                                                        onClick={() => handlePlus(item?.id, item?.isQuantityNaturalNumber ? 1 : 0.5)}>+</div>
                                                </div>
                                            </td>
                                            <td className={styles.saleUnitName_column}>
                                                {item.saleUnitName}
                                            </td>
                                            <td className={styles.totalPrice_column}>
                                                {currency + " " + item.totalPrice.toFixed(2)}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className={styles.delivery_method_wrapper}>
                            <div className={styles.header_container}>
                                <div className={styles.title}>
                                    {t("myAccountInfo.orders.orderDetails.deliveryMethod")}
                                </div>
                            </div>
                            <div className={styles.delivery_method}>
                                {!selectedDeliveryMethod && <div className={styles.errorText}>Wybierz metodę dostawy!</div>}
                                <div className={styles.delivery_details}>
                                    <div className={styles.radio_wrapper}>

                                        {
                                            deliveryMethods && deliveryMethods?.map((item: DeliveryMethod, index: number) => (
                                                <div key={uuid()} className={styles.radio_with_label}>
                                                    <div className={styles.mt_2px = ' ' + styles.delivery_item}>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                checked={selectedDeliveryMethod?.id === item?.id}
                                                                value={item?.id}
                                                                onChange={() => { handleDeliveryMethodChange(item) }}
                                                            />
                                                            <span>
                                                                {item?.name}
                                                            </span>
                                                        </div>

                                                        <span className={styles.delivery_price}>
                                                            {item?.price && currency !== undefined ? `${item?.price.toFixed(2) + " " + currency}` : ` ${"0.00" + currency}`}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {pickupPointMethodChecked && (
                                            <>
                                                <div className={styles.choose_pickup_point}>
                                                    {availablePickupPoints.length > 0 ? (
                                                        <>
                                                            {!selectedPickupPoint && <div className={styles.errorText}>Wybierz punkt odbioru!</div>}
                                                            <PickupPointSelectionPanel selectPickupPoint={selectPickupPoint} pickupPointsWithSellingSessions={pickupPointsWithSellingSessions}></PickupPointSelectionPanel>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className={`${styles.no_pickup_points} ${styles.red}`}>
                                                                {t("order.orderSummary.noAvailablePickupPoints")}
                                                            </div>
                                                            <div className={styles.no_pickup_points}>
                                                                {t("order.orderSummary.buyInOtherDate")}
                                                            </div>
                                                        </>

                                                    )}
                                                </div>
                                            </>
                                        )
                                        }
                                        {
                                            !pickupPointMethodChecked && activeSellingSessions && activeSellingSessions.length <= 0 &&
                                            (
                                                <>
                                                    <div className={`${styles.no_pickup_points} ${styles.red}`}>
                                                        {t("order.orderSummary.noActiveSellingSessions")}
                                                    </div>
                                                    <div className={styles.no_pickup_points}>
                                                        {t("order.orderSummary.buyInOtherDate")}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {futureSellingSessions && futureSellingSessions.length > 0 && selectedDeliveryMethod !== null && (
                            <SellingSessionsTable
                                title={t("sellingSessions.futureSellingSessions")}
                                sellingSessions={futureSellingSessions} />
                        )
                        }
                    </div>
                    <div className={styles.side_block}>
                        <h2 className={styles.cart_title}>{t("cart.summaryCard")}</h2>
                        <div className={styles.products_price}>
                            <p className={styles.label}>{t("cart.productsPrice")}</p>
                            <p className={styles.number}>{calculateTotalPrice(cartItems, false) + " " + currency}</p>
                        </div>
                        {selectedDeliveryMethod &&
                            <div className={styles.products_price}>
                                <p className={styles.label}>{t("cart.delivery")}</p>
                                <p className={styles.number}>{selectedDeliveryMethod?.price.toFixed(2) + " " + currency}</p>
                            </div>
                        }
                        <div className={styles.total_with_VAT}>
                            <p className={styles.label}>{t("cart.totalPrice")}</p>
                            <p className={styles.total}>{calculateTotalPrice(cartItems, true) + " " + currency}</p>
                        </div>
                        <div className={styles.return_button} onClick={() => navigate("/")}>
                            {t("cart.returnToShopping")}
                        </div>

                        <button disabled={shouldBeButtonDisabled} className={styles.buy_button} onClick={() => { buyButtonAction(cartItems) }}>
                            {t("cart.buyAndPay")}
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}
